<template>
  <div>
    <div
      class="dep"
      style="margin: 10px"
      @click="signIn()"
    >
      <h2>非常规活动签到</h2>
  </div>
  <el-dialog
    title="非常规活动签到"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="800px"
  >
    <avue-form
      v-model="form"
      ref='form'
      :option="option"
      :defaults.sync="defaults"
      @submit="submit"
      @reset-change="emptytChange"
    >
      <template slot="type">
        <div>
          <el-button
            style="margin-left: -105px"
            type="primary"
            @click="queryMember"
          >点击查询</el-button>
        </div>
      </template>
      <!-- 能量值充值-选择查询会员-->
      <template slot="member">
        <div>
          <el-select
            placeholder="请选择参与会员"
            v-model="form.member"
            @change="queryMemberSignin"
          >
            <el-option
              v-for="item in dic"
              :key="item.value"
              :value="item"
              :label="item.label + item.mobile"
            ></el-option>
              </el-select>
        </div>
      </template>
      <!-- 选择查询的活动 -->
      <template slot="product">
        <div>
          <el-select
            placeholder="请选择签到的活动"
            v-model="form.product"
            @change="queryProductSignin"
          >
            <el-option
              v-for="item in product"
              :key="item.value"
              :value="item"
              :label="item.label"
            ></el-option>
              </el-select>
        </div>
      </template>
      </avue-form>
      </el-dialog>
      </div>

</template>
<script>
import '../list.scss';

import dataApi from '@/api/manage/workbench';

import tool from '@/utils/tool';

export default {
  data() {
    return {
      dialogVisible: false,
      dic: [],
      defaults: {},
      product: [],
      isSubmit: false,
      form: {
        member: '',
        select: '',
        scenario: 'system',
        datetime: '',
        product: '',
        auth_code: ''
      }
    };
  },
  created() {
    this.getProduct();
    this.form.datetime = this.getTodayDate();
  },
  computed: {
    option() {
      return {
        disabled: false,
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        submitText: '签到',
        emptyText: '关闭',
        menuPosition: 'center',
        tabs: false,
        column: [
          {
            label: '签到日期',
            prop: 'datetime',
            type: 'date',
            span: 8,
            rules: [
              {
                required: true,
                message: '请选择签到的日期',
                trigger: 'blur'
              }
            ],
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            change: ({ value, column }) => {
              this.querySignin(value, column.prop);
              console.log('值改变', value, column.prop);
            }
          },
          {
            label: '签到活动',
            prop: 'product',
            type: 'select',
            span: 16,
            formslot: true,
            rules: [
              {
                required: true,
                message: '请选择签到的活动',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '姓名/手机号',
            span: 21,
            prop: 'select',
            rules: [
              {
                required: true,
                message: '请输入姓名/手机号',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '',
            span: 3,
            prop: 'type',
            formslot: true
          },
          {
            label: '签到会员',
            prop: 'member',
            type: 'select',
            span: 24,
            formslot: true,
            rules: [
              {
                required: true,
                message: '请选择签到会员',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '说明',
            span: 24,
            type: 'textarea',
            prop: 'remark',
            disabled: false
          }
        ]
      };
    }
  },
  watch: {},
  methods: {
    // 卡片点击事件
    signIn() {
      this.dialogVisible = true;
    },
    // 活动签到-会员码查询
    Sign() {
      console.log(this.form.auth_code);
      dataApi.queryActivity.r({ auth_code: this.form.auth_code }).then(res => {
        this.dic = res.data;
      });
    },
    clear() {
      this.getProduct();

      this.isSubmit = false;
      this.form = {
        member: '',
        select: '',
        scenario: 'system',
        datetime: '',
        product: '',
        remark: ''
      };
      this.form.datetime = this.getTodayDate();
    },
    submit(form, done) {
      if (!this.isSubmit) {
        this.$message.warning('数据同步中,暂不能提交~');
        done();
        return false;
      }
      form.member_id = form.member.value;
      form.product_id = form.product.value;
      form.auth_code = '';
      form.coupon_id = '';
      delete form.member;
      delete form.select;
      delete form.product;
      console.log(form);
      // 提交核销
      dataApi.submitSignIn.r(form).then(res => {
        this.dialogVisible = false;
        console.log(res);
        // 清空关闭表单;
        this.clear();
      });

      done();
    },
    // 点击按钮 查询会员列表
    queryMember() {
      if (this.form.select === '') {
        this.$refs.form.validateField('select');
      } else {
        dataApi.getMember.r({ keyword: this.form.select }).then(res => {
          this.$message.success('查询成功~');
          this.dic = [];
          this.dic.push(...res.data);
          this.form.member = '';
        });
      }
    },
    getProduct() {
      dataApi.getProduct.r({ keyword: this.form.select }).then(res => {
        this.product = [];
        this.product.push(...res.data);
      });
    },
    queryMemberSignin(e) {
      this.querySignin(e.value, 'member');
    },
    queryProductSignin(e) {
      this.querySignin(e.value, 'product');
    },
    querySignin(e, field) {
      // 查询指定活动当前会员是否已经购买
      if (
        this.form.member === '' ||
        this.form.product === '' ||
        this.form.datetime === ''
      ) {
        return false;
      }
      this.isSubmit = false;
      const queryData = {
        member_id: field === 'member' ? e : this.form.member.value,
        product_id: field === 'product' ? e : this.form.product.value,
        datetime: field === 'datetime' ? e : this.form.datetime
      };
      console.log('queryData', queryData);
      dataApi.queryMemberProductSign.r(queryData).then(res => {
        this.form.remark = res.data;
        this.isSubmit = true;
      });
    },
    getTodayDate() {
      return tool.dateFormat(new Date(), 'yyyy-MM-dd');
    },
    emptytChange() {
      this.dialogVisible = false;
      this.clear();
    }
  }
};
</script>
