<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
        :data="tableData"
        :option="tableOption"
        @row-save="handleSubmit"
        @refresh-change="getList"
        @row-del="rowDel"
        @on-load="onLoad"
        @search-change="searchChange"
        @search-reset="searchReset"
        :page.sync="page"
    >
    <template slot="member_nameSearch">
        <div style="height: 32px;width: 100%">
          <avue-form ref="form" :option="memberSearchOp" v-model="memberSearchFo" ></avue-form>
        </div>
      </template>
      <template slot="sign_infoSearch">
  <div style="height: 32px;width: 100%;">
    <avue-form
      ref="form"
      :option="activitySearchOp"
      v-model="activitySearchFo"
    ></avue-form>
  </div>
</template>
    <template slot-scope="{ row }"  slot="baby_id">
        <span>
            <img :src=" row.baby.avatar ">
          {{ row.baby.name }}</span>
    </template>
      <template slot-scope="{ type, size, row, index }" slot="menu">
        <el-button
            icon="el-icon-edit-outline"
            :size="size"
            :type="type"
            @click.stop="commentsEdit(row, index)"
        >编辑</el-button
        >
        <el-button
            icon="el-icon-edit-outline"
            :size="size"
            :type="type"
            @click.stop="commentsDialogForm(row, index)"
        >点评</el-button
        >
        <el-button
            icon="el-icon-edit-outline"
            :size="size"
            :type="type"
            @click.stop="rowDel(row, index)"
        >删除</el-button
        >
      </template>
    </avue-crud>

    <el-drawer
        title="点评"
        :before-close="drawerClose"
        :visible.sync="dialogVisible"
        size="80%"
    >
      <avue-crud
          :data="commentsData"
          :option="commentsOption"
          @row-save="commentsSubmit"
          @row-del="commentsDel"
          @search-change="searchChange"
          @search-reset="searchReset"
          :page.sync="page"
      >
      </avue-crud>
    </el-drawer>
    <!--成长记录-->
    <el-drawer
        title="成长记录"
        size="80%"
        :close-on-click-modal="false"
        :visible.sync="growthVisible"
    >
      <avue-form
          ref="planForm"
          v-model="formGrowthDa"
          :option="formGrowthOp"
          @submit="growthRowUpdate"
          @reset-change="growthEmptytChange"
      >
        <template slot="type">
          <el-select  placeholder="请选择所属分类" v-model="formGrowthDa.type">
            <el-option  :value="1" label="图文"></el-option>
            <el-option  :value="2" label="视频"></el-option>
          </el-select>
        </template>
      </avue-form>
    </el-drawer>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
