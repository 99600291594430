<template>
  <div class="deposit">
    <!--页面渲染-->
    <Signin></Signin>
    <NonSign></NonSign>
    <div
      class="dep"
      style="margin: 10px"
      @click="depositPopup('addMember')"
    >
      <h2>新增会员</h2>
  </div>
  <!-- 能量值充值 -->
  <recharge ref="recharge" />
  <Exchange></Exchange>
  <div
    class="dep"
    style="margin: 10px"
    @click="depositPopup('activityExchange')"
  >
    <h2>活动兑换</h2>
    </div>
    <!-- 会员退款 -->
    <refunds />

    <!-- 能量值充值-微信/支付宝扫码弹窗 -->
    <el-dialog
      :visible.sync="depositShow"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="50%"
    >
      <span>这是一段信息</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        </span>
        </el-dialog>

        <!-- 弹窗 -->
        <el-dialog
          :title="depositTitle"
          :visible.sync="dialogVisible"
          :close-on-click-modal="false"
          width="800px"
        >
          <!--  表单  -->
          <avue-form
            v-model="form"
            ref='form'
            :option="option"
            :defaults.sync="defaults"
            @submit="submit"
            @reset-change="emptytChange"
          >
            <!--   活动兑换   -->
            <template slot="subtotal">
              <div>
                {{form.activity_plan === '' ? "请选择活动/属性" : form.join * form.activity_plan.price}}
              </div>
            </template>
            <!--活动兑换-时间-->
            <template slot="activity_plan">
              <div>
                <el-select
                  placeholder="请选择活动属性"
                  v-model="form.activity_plan"
                >
                  <el-option
                    v-for="item in dic"
                    :key="item.id"
                    :value="item"
                    :label="item.sku_text"
                  ></el-option>
                    </el-select>
              </div>
            </template>

            <!-- 活动签到-查询按钮 -->
            <template slot="activityInquire">
              <div>
                <el-button
                  style="margin-left: -105px"
                  type="primary"
                  @click="Sign"
                >点击查询</el-button>
              </div>
            </template>

            <!-- 活动签到-签到活动列表 -->
            <template slot="memberSign">
              <div>
                <el-select
                  placeholder="请选择活动区域"
                  v-model="form.memberSign"
                >
                  <el-option
                    v-for="item in dic"
                    :key="item.value"
                    :value="item"
                    :label="item.activity_title"
                  ></el-option>
                    </el-select>
              </div>
            </template>
            </avue-form>
            </el-dialog>

            <!-- 新增会员跳转充值 -->
            <el-dialog
              title=""
              :visible.sync="addMemberDialogVisible"
              width="30%"
              center
            >
              <span>是否需要跳转到能量值充值</span>
              <span
                slot="footer"
                class="dialog-footer"
              >
                <el-button @click="addMemberDialogVisible = false">关闭</el-button>
                <el-button
                  type="primary"
                  @click="notarize"
                >跳转</el-button>
                  </span>
                  </el-dialog>
                  </div>
</template>

<script>
import List from './list';

export default List;
</script>
