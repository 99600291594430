<!--
  会员退款
-->
<template>
  <div>
    <div
      class="dep"
      style="margin: 10px"
      @click="refundsClick()"
    >
      <h2>会员退款</h2>
  </div>
  <!-- 会员退款弹窗 -->
  <el-dialog
    title="会员退款"
    :visible.sync="refundsVisible"
    :close-on-click-modal="false"
    width="800px"
  >
    <!--  表单  -->
    <avue-form
      v-model="form"
      ref='form'
      :option="option"
      :defaults.sync="defaults"
      @submit="refundsSubmit"
      @reset-change="emptytChange"
    >
      <!-- 会员退款-选择查询会员-->
      <template slot="member">
        <div>
          <el-select
            placeholder="请选择会员"
            clearable
            @change="queryTopup"
            v-model="form.member"
          >
            <el-option
              v-for="item in dic"
              :key="item.value"
              :value="item"
              :label="item.label"
            ></el-option>
          </el-select>
        </div>
      </template>
      <!-- 会员充值记录-->
      <template slot="energy_topup">
        <div>
          <el-select
            placeholder="请选择充值记录"
            clearable
            v-model="form.energy_topup"
            @change="changeEnergyTopup"
          >
            <el-option
              v-for="(item,index) in topupLog"
              :key="index"
              :label="item.label"
              :value="index"
            >
            </el-option>
          </el-select>
        </div>
      </template>
      <!-- 会员退款 -->
      <template slot="energy_sum">
        <div>
          {{form.energy_sum}}
        </div>
      </template>
      <!-- 会员退款 -->
      <template slot="residue_energy">
        <div>
          {{form.member.length === 0 ? '请选择充值会员' : form.member.energy}}
        </div>
      </template>
      <!-- 会员退款-退款方式 -->
      <template slot="pay_type">
        <el-radio
          v-model="form.pay_type"
          label="cash"
        >线下支付</el-radio>
          <el-radio
            v-model="form.pay_type"
            label="wxpay"
          >微信支付</el-radio>
            <el-radio
              v-model="form.pay_type"
              label="alipay"
            >支付宝支付</el-radio>
      </template>
      <!-- 会员退款-查询按钮 -->
      <template slot="type">
        <div>
          <el-button
            style="margin-left: -115px"
            type="primary"
            @click="queryMember"
          >查询会员</el-button>
        </div>
      </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import '../list.scss';
import dataApi from '@/api/manage/workbench';
import energy from '@/api/member/energy';

export default {
  data() {
    return {
      // 会员退款弹窗
      refundsVisible: false,
      dic: [],
      topupLog: [],
      form: {
        pay_type: 'cash',
        member: '',
        energy_sum: '',
        remark: '',
        depositWay: 0,
        energy_topup: '',
        depositTemplate: ''
      },
      defaults: {},
      formDa: {},
      // 表单配置项
      option: {
        disabled: false,
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 120,
        gutter: 0,
        emptyText: '关闭',
        menuPosition: 'center',
        tabs: false,
        column: [
          {
            label: '姓名/手机号',
            span: 21,
            prop: 'select',
            rules: [
              {
                required: true,
                message: '请输入姓名/手机号',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '',
            span: 3,
            prop: 'type',
            formslot: true
          },
          {
            label: '充值会员',
            prop: 'member',
            type: 'select',
            span: 24,
            formslot: true,
            rules: [
              {
                required: true,
                message: '请选择充值会员',
                trigger: 'change'
              }
            ]
          },
          {
            label: '充值记录',
            prop: 'energy_topup',
            type: 'select',
            span: 24,
            formslot: true,
            rules: [
              {
                required: true,
                message: '请选择充值记录',
                trigger: 'change'
              }
            ]
          },
          {
            label: '退款方式',
            prop: 'pay_type',
            span: 24,
            formslot: true,
            rules: [
              {
                required: true,
                message: '请输入选择退款方式',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '剩余能量值',
            span: 8,
            prop: 'residue_energy',
            formslot: true
          },
          {
            label: '可退还能量值',
            span: 6,
            prop: 'energy_sum',
            formslot: true
          },
          {
            label: '退款金额',
            span: 10,
            prop: 'refund_money',
            type: 'input',
            disabled: true,
            rules: [
              {
                required: true,
                message: '请查询退款金额',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '说明',
            span: 24,
            type: 'textarea',
            prop: 'remark'
          }
        ]
      },
      depositShow: false
    };
  },
  created() {},
  computed: {
  },
  methods: {
    numberChange(value) {
      if (this.form.member) {
        if (value > this.form.member.energy) {
          this.form.number = this.form.member.energy;
        }
      } else {
        this.form.number = 0;
      }
    },
    clear() {
      this.form = {
        select: '',
        pay_type: 'cash',
        member: '',
        number: '',
        remark: '',
        depositWay: 0,
        depositTemplate: '',
        energy_topup: '',
        energy_sum: 0,
        refund_money: 0
      };
    },
    // 会员 退款弹窗
    refundsClick() {
      this.refundsVisible = true;
    },
    // 会员表单退款提交事件
    refundsSubmit(form, done) {
      // console.log('qweqwe');
      form.member_id = form.member.value;
      delete form.member;
      delete form.select;
      delete form.depositWay;
      delete form.energy_topup;
      delete form.energy_sum;
      delete form.refund_money;
      this.topupLog = [];
      this.energy_topup = 0;
      
      this.formDa.pay_type = form.pay_type;
      dataApi.refund.r(this.formDa).then(() => {
        done();
        this.refundsVisible = false;
        this.clear();
        this.$message.success('操作成功~');
      });
    },
    emptytChange() {
      this.refundsVisible = false;
      this.clear();
    },
    // 点击按钮 查询会员列表
    queryMember() {
      if (this.form.select === '') {
        this.$refs.form.validateField('select');
      } else {
        dataApi.getMember.r({ keyword: this.form.select }).then(res => {
          this.dic = [];
          this.dic.push(...res.data);
        });
      }
    },
    // 查询会员充值日志
    queryTopup(val) {
      console.log('top_up', val);
      energy.getSelect.r({ member_id: val.value }).then(res => {
        this.topupLog = [];
        this.topupLog.push(...res.data);
      });
    },
    changeEnergyTopup(val) {
      this.formDa = this.topupLog[val];
      this.form.energy_sum = this.formDa.energy - this.formDa.use_energy;
      this.form.refund_money = this.formDa.refund_money;
    }
  }
};
</script>

<style scoped>
</style>
