<template>
  <div>
    <div
      class="dep sign"
      style="margin: 10px"
      @click="signIn()"
    >
      <h2>常规活动签到</h2>
  </div>
  <el-dialog
    title="常规活动签到"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="800px"
  >
    <avue-form
      v-model="form"
      ref='form'
      :option="option"
      :defaults.sync="defaults"
      @submit="submit"
      @reset-change="emptytChange"
    >
      <!-- 能量值充值-选择查询会员-->
      <template slot="member">
        <div>
          <el-select
            placeholder="请选择参与会员"
            multiple
            v-model="form.member"
          >
            <el-option
              v-for="item in member"
              :key="item.value"
              :value="item.value"
              :label="item.label + item.mobile"
              :disabled="item.disabled"
            ></el-option>
              </el-select>
        </div>
      </template>
      <!-- 选择查询的活动 -->
      <template slot="product_id">
        <div>
          <el-select
            placeholder="请选择签到的活动"
            v-model="form.product_id"
            @change="getTeacherMember"
          >
            <el-option
              v-for="item in product"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
              </el-select>
        </div>
      </template>
      </avue-form>
    </el-dialog>
  </div>
</template>
<script>
import '../list.scss';

import dataApi from '@/api/manage/workbench';

import tool from '@/utils/tool';

export default {
  data() {
    return {
      dialogVisible: false,
      defaults: {},
      product: [], // 获取集合
      teacher: [], // 策划师集合
      member: [], // 会员集合
      isSubmit: false,
      form: {
        member: '',
        select: '',
        teacher_id: '',
        aid: '',
        scenario: 'system',
        datetime: '',
        product_id: ''
      }
    };
  },
  created() {
    this.form.datetime = this.getTodayDate();
  },
  computed: {
    option() {
      return {
        disabled: false,
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        submitText: '签到',
        emptyText: '关闭',
        menuPosition: 'center',
        tabs: false,
        column: [
          {
            label: '签到日期',
            prop: 'datetime',
            type: 'date',
            span: 12,
            rules: [
              {
                required: true,
                message: '请选择签到的日期',
                trigger: 'blur'
              }
            ],
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            change: ({ value, column }) => {
              this.changeDateTime();
              console.log('值改变', value, column.prop);
            }
          },
          {
            type: 'select',
            label: '策划师',
            dicUrl: `${this.$baseUrl}general/get_teacher`,
            dicQuery: {},
            dicMethod: 'post',
            span: 12,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'teacher_id',
            required: true,
            rules: [
              {
                required: true,
                message: '请选择签到的策划师'
              }
            ],
            change: ({ value, column }) => {
              this.getProductCourse(value, column.prop);
              console.log('值改变', value, column.prop);
            }
          },
          {
            label: '签到活动',
            prop: 'product_id',
            type: 'select',
            span: 24,
            formslot: true,
            rules: [
              {
                required: true,
                message: '请选择签到的活动',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '签到会员',
            prop: 'member',
            type: 'select',
            span: 24,
            multiple: true,
            formslot: true,
            rules: [
              {
                required: true,
                message: '请选择签到会员',
                trigger: 'blur'
              }
            ]
          },
          {
            type: 'select',
            label: '助理策划师',
            dicUrl: `${this.$baseUrl}general/get_teacher`,
            dicQuery: {},
            dicMethod: 'post',
            span: 12,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'aid'
          },
          {
            label: '说明',
            span: 24,
            type: 'textarea',
            prop: 'remark',
            disabled: false
          }
        ]
      };
    }
  },
  watch: {},
  methods: {
    // 卡片点击事件
    signIn() {
      this.dialogVisible = true;
    },
    clear() {
      this.isSubmit = false;
      this.form = {
        member: '',
        select: '',
        teacher_id: '',
        datetime: '',
        product_id: '',
        remark: ''
      };
      this.form.datetime = this.getTodayDate();
    },
    submit(form, done) {
      // 提交核销
      dataApi.submitBatchSignIn.r(form).then(res => {
        this.dialogVisible = false;
        // 清空关闭表单;
        this.clear();
        this.$message.success(res.msg);
      });
      done();
    },
    changeDateTime() {
      // 改变日期清空后续表单
      this.form = {
        member: '',
        select: '',
        teacher_id: '',
        product_id: ''
      };
    },
    getTeacherMember(e) {
      // 根据老师和活动获取会员
      const param = {
        date: this.form.datetime,
        product_id: e,
        teacher_id: this.form.teacher_id
      };
      dataApi.getTeacherMember.r(param).then(res => {
        this.$message.success('查询成功~');
        this.member = [];
        this.member.push(...res.data);
        this.form.member = '';
      });
    },
    getProductCourse(e) {
      // 根据老师获取课程
      const param = {
        date: this.form.datetime,
        teacher_id: e
      };
      this.form.product_id = '';
      dataApi.getCourse.r(param).then(res => {
        this.product = [];
        this.product.push(...res.data);
      });
    },
    getTodayDate() {
      return tool.dateFormat(new Date(), 'yyyy-MM-dd');
    },
    emptytChange() {
      this.dialogVisible = false;
      this.clear();
    }
  }
};
</script>
