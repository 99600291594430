import './list.scss';
import dataApi from '@/api/manage/grow.js';
import member from '@/api/member/member';
import product from '@/api/product/product';
/*
 * 文章列表
 *  */
export default {
  name: 'article-list',
  components: {},
  data() {
    return {
      growthVisible: false,
      formGrowthDa: {
        type: 1,
        source: 1,
        number: 0,
        status: 1,
        is_show: 1
      },
      formGrowthOp: {
        column: [
          {
            label: '所属分类',
            prop: 'type',
            span: 24,
            formslot: true,
            display: true,
            value: 1,
            rules: [
              {
                required: true,
                message: '请选择所属分类',
                trigger: 'blur'
              }
            ]
          },
          {
            type: 'radio',
            label: '来源',
            value: 1,
            control: (val) => {
              if (val === 1) {
                return {
                  // 文章内容
                  content: {
                    display: true
                  },
                  // 外链地址
                  source_url: {
                    display: false
                  }
                };
              }
              if (val === 3) {
                return {
                  source_url: {
                    label: '视频feedId',
                    display: true,
                    tip: 'feedId唯一标识某一条视频，获取视频的feedId需要登录视频号助手，在「动态管理」模块可以复制自己发表的每个视频对应的feedId。'
                  },
                  content: {
                    display: false
                  }
                };
              }
              return {
                content: {
                  display: false
                },
                source_url: {
                  label: '外链地址',
                  display: true
                }
              };
            },
            prop: 'source',
            dicData: [
              {
                label: '本地',
                value: 1
              },
              {
                label: '公众号',
                value: 2
              },
              {
                label: '视频号',
                value: 3
              }
            ],
            span: 12,
            display: true,
            required: true,
            rules: [
              {
                required: true,
                message: '请选择来源'
              }
            ]
          },
          {
            type: 'number',
            label: '排序',
            span: 12,
            display: true,
            prop: 'sorting',
            value: 0
          },
          {
            label: '图集',
            prop: 'images',
            type: 'upload',
            limit: 10,
            span: 24,
            addDisplay: true,
            editDisplay: true,
            hide: true,
            propsHttp: {},
            dataType: 'string',
            listType: 'picture',
            accept: 'image/png, image/jpeg',
            oss: '',
            tip: '只能上传jpg/png文件，且不超过500kb',
            action: `${this.$baseUrl}system/upload`,
            required: true
            // rules: [
            //   {
            //     required: true,
            //     message: '图片必须上传'
            //   }
            // ]
          },
          {
            type: 'textarea',
            label: '外链地址',
            span: 24,
            prop: 'source_url',
            maxlength: 1000,
            minRows: 4,
            maxRows: 6,
            showWordLimit: true,
            display: false
          },
          {
            type: 'ueditor',
            component: 'AvueUeditor',
            label: '精彩瞬间',
            span: 24,
            display: true,
            prop: 'content',
            maxlength: 800,
            minRows: 4,
            maxRows: 6,
            showWordLimit: true,
            action: `${this.$baseUrl}system/upload`
          },
          {
            type: 'radio',
            label: '状态',
            dicData: [
              {
                label: '禁用',
                value: -1
              },
              {
                label: '启用',
                value: 1
              }
            ],
            value: 1,
            span: 12,
            display: true,
            prop: 'status',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true
          },
          {
            // 显示状态
            label: '是否公开',
            type: 'radio',
            value: 1,
            dicData: [
              {
                label: '公开',
                value: 1
              },
              {
                label: '仅家长观看',
                value: 0
              }
            ],
            props: {
              label: 'label',
              value: 'value'
            },
            span: 12,
            display: true,
            prop: 'is_show'
          }
        ],
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 100,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: true,
        emptyText: '关闭',
        menuPosition: 'center'
      },
      growth_id: '',
      dataApi,
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      dialogVisible: false,
      tableData: [],
      commentsData: [],
      memberSearchOp: {
        span: 24,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            labelWidth: 0,
            label: '',
            prop: 'table',
            type: 'table',
            children: {
              border: true,
              searchMenuSpan: 8,
              column: [
                {
                  type: 'input',
                  label: '会员名称',
                  display: true,
                  prop: 'name',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  type: 'input',
                  label: '手机号',
                  display: true,
                  prop: 'mobile',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  label: '能量值',
                  prop: 'energy'
                },
                {
                  label: '赠送能量值',
                  prop: 'giving_energy'
                }
              ]
            },
            formatter: (row) => {
              if (!row.name) return '';
              return `${row.name}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                return;
              }
              if (page) {
                console.log(page);
              }
              // 请求列表数据
              member.getList.r(data).then((res) => {
                // 渲染数据
                callback({
                  total: res.data.total,
                  data: [...res.data.list]
                });
              });
            },
            props: {
              label: 'name',
              value: 'id'
            }
          }
        ]
      },
      memberSearchFo: {},
      activitySearchFo: {},
      activitySearchOp: {
        span: 24,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            labelWidth: 0,
            label: '',
            prop: 'table',
            type: 'table',
            children: {
              border: true,
              searchMenuSpan: 8,
              column: [
                {
                  type: 'input',
                  label: '活动名称',
                  display: true,
                  prop: 'name',
                  required: true,
                  searchLabelWidth: 100,
                  search: true,
                  searchRules: [
                    {
                      required: true,
                      message: '请输入活动名称',
                      trigger: 'blur'
                    }
                  ],
                  rules: [
                    {
                      required: true,
                      message: '名称必须填写'
                    }
                  ]
                },
                {
                  type: 'tree',
                  label: '所属分类',
                  search: true,
                  props: {
                    label: 'label',
                    value: 'value'
                  },
                  dicUrl: `${this.$baseUrl}general/get_product_category`,
                  dicQuery: {},
                  dicMethod: 'post',
                  span: 12,
                  display: true,
                  prop: 'product_category_id',
                  required: true,
                  rules: [
                    {
                      required: true,
                      message: '所属分类必须填写'
                    }
                  ]
                }
              ]
            },
            formatter: (row) => {
              if (!row.name) return '';
              return `${row.name}`;
            },
            onLoad: ({ value, page }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                return;
              }
              // 请求列表数据
              product.getList
                .r({ page: page.currentPage, pageSize: page.pageSize })
                .then((res) => {
                  // 渲染数据
                  callback({
                    total: res.data.total,
                    data: [...res.data.list]
                  });
                });
            },
            props: {
              label: 'name',
              value: 'id'
            }
          }
        ]
      },
      tableOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        addBtn: false,
        ...this.$store.getters.tableConfig,
        selection: false,
        delBtn: false,
        editBtn: false,
        column: [
          {
            label: '会员名称',
            search: true,
            prop: 'member_name'
          },
          {
            label: '参与活动',
            prop: 'sign_info',
            search: true
          },
          {
            label: '策划师',
            prop: 'teacher_name'
          },
          {
            label: '策划师',
            prop: 'teacher_id',
            type: 'select',
            search: true,
            props: {
              label: 'label',
              value: 'value'
            },
            dicUrl: `${this.$baseUrl}general/get_teacher`,
            dicQuery: {},
            dicMethod: 'post',
            hide: true,
            showColumn: false
          },
          {
            label: '参与宝宝',
            prop: 'baby_id',
            slot: true
          },
          {
            label: '签到日期',
            prop: 'sign_date'
          },
          {
            label: '创建日期',
            prop: 'create_time'
          },
          {
            type: 'radio',
            label: '日期类型',
            value: 'create',
            dicData: [
              {
                label: '创建日期',
                value: 'create'
              },
              {
                label: '签到日期',
                value: 'sign'
              }
            ],
            hide: true,
            display: false,
            search: 'true',
            prop: 'date_type',
            span: 6
          },
          {
            type: 'datetime',
            searchRange: true,
            label: '日期范围',
            span: 8,
            hide: true,
            display: false,
            search: 'true',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            prop: 'datetime'
          }
        ]
      },
      commentsOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        addBtn: true,
        ...this.$store.getters.tableConfig,
        selection: false,
        delBtn: true,
        editBtn: true,
        column: [
          {
            label: '老师头像',
            prop: 'avatar',
            addDisplay: false,
            editDisplay: false,
            type: 'upload'
          },
          {
            label: '老师名称',
            prop: 'name',
            addDisplay: false,
            editDisplay: false,
            search: true,
            type: 'input'
          },
          {
            type: 'select',
            label: '点评老师',
            dicUrl: `${this.$baseUrl}general/get_teacher`,
            dicQuery: {},
            dicMethod: 'post',
            span: 24,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'teacher_id',
            required: true,
            rules: [
              {
                required: true,
                message: '点评老师必须填写'
              }
            ]
          },
          {
            type: 'textarea',
            label: '点评内容',
            prop: 'content',
            span: 24,
            rules: [
              {
                required: true,
                message: '点评内容必须填写'
              }
            ]
          },
          {
            label: '是否展示',
            prop: 'is_show',
            type: 'radio',
            value: 1,
            dicData: [
              {
                label: '展示',
                value: 1
              },
              {
                label: '不展示',
                value: 0
              }
            ],
            rules: [
              {
                required: true,
                message: '是否展示必须填写'
              }
            ]
          },
          {
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          }
        ]
      },
      searchData: { date_type: 'create' }
    };
  },
  computed: {},
  mounted() {},
  filters: {},
  methods: {
    // 成长中心提交
    growthRowUpdate(form, done) {
      console.log(form);
      dataApi.update.r(form).then(() => {
        this.growthVisible = false;
      });
      done();
    },
    // 成长中心关闭
    growthEmptytChange() {
      this.growthVisible = false;
    },
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      if (this.activitySearchFo.table) {
        params.activity_id = this.activitySearchFo.table;
      }
      if (this.memberSearchFo.table) {
        params.member_id = this.memberSearchFo.table;
      }
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.start_time = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;

      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {
        date_type: 'create'
      };
      this.getList();
    },
    commentsEdit(row) {
      this.getInfo(row);
      this.growthVisible = true;
    },
    // 添加
    commentsDialogForm(row) {
      this.dialogVisible = true;
      this.growth_id = row.id;
      this.growthreview(row.id);
    },
    drawerClose() {
      console.log(this.formDa);
      this.dialogVisible = false;
      // this.$refs.form.resetForm();
    },
    handleSubmit(form, done) {
      if (form.id) {
        dataApi.update.r(form).then(() => {
          this.getList();
          this.drawerClose();
          done(form);
        });
      } else {
        dataApi.add.r(form).then(() => {
          this.getList();
          this.drawerClose();
          done(form);
        });
      }
      done();
    },
    // 活动点评新增
    commentsSubmit(form, done) {
      form.growth_id = this.growth_id;
      dataApi.growthreviewAdd.r(form).then(() => {
        this.growthreview(this.growth_id);
      });
      done();
    },
    commentsDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.growthreviewDel.r({ id: form.id }).then(() => {
          this.growthreview(this.growth_id);
          this.$message.success('删除成功');
        });
      });
    },
    delRedact() {
      this.dialogVisible = false;
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form.id }).then(() => {
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    getInfo(row) {
      this.formDa = {};
      dataApi.getOne.r({ id: row.id }).then((res) => {
        this.formGrowthDa = res.data;
      });
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        res.data.list.forEach((item) => {
          item.name = item.baby.name;
          item.avatar = item.baby.avatar;
        });
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    },
    growthreview(id) {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        growth_id: id,
        ...this.searchData
      };
      dataApi.growthreviewGetlist.r(postData).then((res) => {
        res.data.list.forEach((item) => {
          item.name = item.teacher.name;
          item.avatar = item.teacher.avatar;
        });
        this.commentsData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
