<template>
  <div>
    <div
      class="dep sign"
      style="margin: 10px"
      @click="exchange()"
    >
      <h2>常规活动兑换</h2>
  </div>
  <el-dialog
    title="常规活动兑换"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="800px"
  >
    <avue-form
      v-model="form"
      ref='form'
      :option="option"
      :defaults.sync="defaults"
      @submit="submit"
      @reset-change="emptytChange"
    >
    <!-- 能量值充值-查询按钮 -->
      <template slot="type">
        <div>
          <el-button
            style="margin-left: -105px"
            type="primary"
            @click="queryMember"
          >点击查询</el-button>
        </div>
      </template>
      <!-- 能量值充值-选择查询会员-->
      <template slot="member">
        <div>
          <el-select
            placeholder="请选择参与会员"
            multiple
            v-model="form.member"
          >
            <el-option
              v-for="item in member"
              :key="item.value"
              :value="item.value"
              :label="item.label + item.mobile"
              :disabled="item.disabled"
            ></el-option>
              </el-select>
        </div>
      </template>
      <!-- 选择查询的活动 -->
      <template slot="product">
        <div>
          <el-select
            placeholder="请选择签到的活动"
            v-model="form.product"
            @change="setProductForm"
          >
            <el-option
              v-for="item in product"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </div>
      </template>
      </avue-form>
      </el-dialog>
      </div>

</template>
<script>
import '../list.scss';

import dataApi from '@/api/manage/workbench';

export default {
  data() {
    return {
      dialogVisible: false,
      defaults: {},
      product: [], // 获取集合
      teacher: [], // 策划师集合
      member: [], // 会员集合
      isSubmit: false,
      form: {
        member: '',
        select: '',
        course_id: '',
        teacher_id: '',
        product_id: '',
        activity_plan_id: ''
      }
    };
  },
  created() {
    // this.getProduct();
  },
  computed: {
    option() {
      return {
        disabled: false,
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        submitText: '立即兑换',
        emptyText: '关闭',
        menuPosition: 'center',
        tabs: false,
        column: [
          {
            type: 'select',
            label: '策划师',
            dicUrl: `${this.$baseUrl}general/get_teacher`,
            dicQuery: {},
            dicMethod: 'post',
            span: 24,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'teacher_id',
            required: true,
            rules: [
              {
                required: true,
                message: '请选择签到的策划师'
              }
            ],
            change: ({ value, column }) => {
              this.getProductCourse(value, column.prop);
              console.log('值改变', value, column.prop);
            }
          },
          {
            label: '兑换活动',
            prop: 'product',
            type: 'select',
            span: 24,
            formslot: true,
            rules: [
              {
                required: true,
                message: '请选择签到的活动',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '姓名/手机号',
            span: 21,
            prop: 'select',
            rules: [
              {
                required: true,
                message: '请输入姓名/手机号',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '',
            span: 3,
            prop: 'type',
            formslot: true
          },
          {
            label: '兑换会员',
            prop: 'member',
            type: 'select',
            span: 24,
            multiple: true,
            formslot: true,
            rules: [
              {
                required: true,
                message: '请选择签到会员',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '说明',
            span: 24,
            type: 'textarea',
            prop: 'remark',
            disabled: false
          }
        ]
      };
    }
  },
  watch: {},
  methods: {
    // 卡片点击事件
    exchange() {
      this.dialogVisible = true;
    },
    clear() {
      this.isSubmit = false;
      this.form = {
        member: '',
        select: '',
        course_id: '',
        teacher_id: '',
        product_id: '',
        activity_plan_id: '',
        remark: ''
      };
    },
    // 点击按钮 查询会员列表
    queryMember() {
      if (this.form.select === '') {
        this.$refs.form.validateField('select');
      } else {
        dataApi.getMember.r({ keyword: this.form.select }).then(res => {
          this.$message.success('查询成功~');
          // this.dic = [];
          this.member.push(...res.data);
          // this.form.member = '';
        });
      }
    },
    submit(form, done) {
      console.log(form);
      // 提交核销
      dataApi.submitExchangeBatch.r(form).then(res => {
        this.dialogVisible = false;
        console.log(res);
        // 清空关闭表单;
        this.clear();
        this.$message.success(res.msg);
      });

      done();
    },
    setProductForm(e) {
      console.log(e);
      // eslint-disable-next-line array-callback-return
      const result = this.product.find((item) => {
        if (item.value === e) {
          return item;
        }
      });
      this.form.course_id = e;
      this.form.product_id = result.product_id;
      this.form.activity_plan_id = result.sku_stock_id;
      console.log(result);
    },
    getProductCourse(e) {
      // 根据老师获取课程
      const param = {
        date: this.form.datetime,
        teacher_id: e
      };
      this.form.product = '';
      dataApi.getCourse.r(param).then(res => {
        this.product = [];
        this.product.push(...res.data);
      });
    },
    emptytChange() {
      this.dialogVisible = false;
      this.clear();
    }
  }
};
</script>
