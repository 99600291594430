import './list.scss';
import dataApi from '@/api/manage/workbench';
import activity from '@/api/product/product';
import refunds from './components/memberRefunds.vue';
import recharge from './components/recharge.vue';
import Signin from './components/sign.vue';
import NonSign from './components/nonSign.vue';
import Exchange from './components/exchange.vue';
/*
 * 工作台
 *  */
export default {
  data() {
    return {
      addMemberDialogVisible: false,
      moneyDisabled: false,
      // 下拉列表
      dic: [],
      depositDic: [],
      remark: '',
      // 表单回调
      form: {
        text: '',
        member: '',
        money: '',
        remark: '',
        join: 1,
        activity_plan: ''
      },
      defaults: {},
      // 控制弹窗显示
      dialogVisible: false,
      // 控制弹窗标题
      depositTitle: '',
      // 充值窗口
      depositShow: false,
      tabData: ''
    };
  },
  computed: {
    energy() {
      // eslint-disable-next-line no-restricted-globals
      return isNaN(this.form.money * 1) ? 0 : this.form.money * 1;
    },

    option() {
      return {
        disabled: false,
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        emptyText: '关闭',
        menuPosition: 'center',
        tabs: false,
        column: []
      };
    }
  },
  components: { refunds, recharge, Signin, NonSign, Exchange },
  methods: {
    addMember(form) {
      dataApi.addMember
        .r(form)
        .then((res) => {
          console.log('res', res);
          this.dialogVisible = false;
          this.addMemberDialogVisible = true;
          this.form.member = res.data.name;
          this.form.select = res.data.name;
          this.form.member_id = res.data.id;

          // 清空关闭表单
          this.dic = [];
          this.delForm();
          this.option.column = '';
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 提交-活动签到
    activity(form) {
      dataApi.getNuclear
        .r({
          member_id: form.memberSign.member_id,
          exchange_id: form.memberSign.id
        })
        .then(() => {
          this.dialogVisible = false;

          // 清空关闭表单
          this.dic = [];
          this.delForm();
          this.option.column = '';
        });
    },
    // 活动兑换
    activityExchange(form) {
      if (form.join < 1) {
        this.$message.error('提交失败，参与人数错误');
      } else {
        dataApi.addExchange
          .r({
            member_id: form.exchange_member,
            activity_id: form.activity,
            activity_plan_id: form.activity_plan.id,
            quantity: form.join,
            energy: form.activity_plan.price,
            subtotal: form.join * form.activity_plan.price,
            remark: form.remark
          })
          .then(() => {
            this.dialogVisible = false;

            // 清空关闭表单
            this.dic = [];
            this.delForm();
            this.option.column = '';
            this.$message.success('活动兑换成功~');
          });
      }
    },
    // 点击按钮 查询会员列表
    queryMember() {
      if (this.form.select === '') {
        this.$refs.form.validateField('select');
      } else {
        dataApi.getMember.r({ keyword: this.form.select }).then((res) => {
          this.dic = [];
          this.dic.push(...res.data);
        });
      }
    },
    // 提交
    submit(form, done) {
      if (
        'activity' in form &&
        'exchange_member' in form &&
        'exchange_member' in form
      ) {
        this.activityExchange(form);
      }
      if ('auth_code' in form && 'memberSign' in form) {
        this.activity(form);
      }
      if ('member_group_id' in form && 'member_rank_id' in form) {
        this.addMember(form);
      }
      done();
    },
    // 清空
    emptytChange() {
      this.dic = [];
      this.delForm();
      this.dialogVisible = false;
      this.option.column = '';
    },
    // 清空表单
    delForm() {
      this.form = {
        select: '',
        member: '',
        money: '',
        way: 0,
        code: '',
        remark: '',
        join: 1,
        activity_plan: '',
        depositWay: 0,
        depositTemplate: '',
        member_group_id: '',
        member_rank_id: '',
        mobile: '',
        name: '',
        sex: 1
      };
    },
    // 活动签到-会员码查询
    Sign() {
      console.log(this.form.auth_code);
      dataApi.queryActivity
        .r({ auth_code: this.form.auth_code })
        .then((res) => {
          this.dic = res.data;
        });
    },
    // 新增会员跳转能量值充值
    notarize() {
      this.depositPopup('workbench');
      this.addMemberDialogVisible = false;
      this.$refs.recharge.recharge();
    },
    depositChange() {
      this.form.money = this.form.depositTemplate.price;
    },
    // 弹窗
    depositPopup(value) {
      // eslint-disable-next-line default-case
      switch (value) {
        case 'activityExchange':
          this.option.column = [
            {
              label: '活动',
              prop: 'activity',
              type: 'table',
              span: 24,
              rules: [
                {
                  required: true,
                  message: '请选择活动',
                  trigger: 'change'
                }
              ],
              children: {
                border: true,
                searchMenuSpan: 8,
                column: [
                  {
                    type: 'tree',
                    label: '所属分类',
                    search: true,
                    props: {
                      label: 'label',
                      value: 'value'
                    },
                    dicUrl: `${this.$baseUrl}general/get_product_category`,
                    dicQuery: {},
                    dicMethod: 'post',
                    span: 12,
                    display: true,
                    prop: 'product_category_id',
                    required: true,
                    rules: [
                      {
                        required: true,
                        message: '所属分类必须填写'
                      }
                    ]
                  },
                  {
                    type: 'input',
                    label: '活动名称',
                    display: true,
                    prop: 'name',
                    searchLabelWidth: 100,
                    search: true,
                    rules: [
                      {
                        required: true,
                        message: '名称必须填写'
                      }
                    ]
                  }
                ]
              },
              formatter: (row) => {
                if (!row.name) return '';
                activity.getSkuInfo.r({ product_id: row.id }).then((res) => {
                  this.dic = [...res.data];
                });
                return `${row.name}`;
              },
              onLoad: ({ value, page }, callback) => {
                // 首次加载去查询对应的值
                if (value) {
                  return;
                }
                // 请求列表数据
                activity.getList
                  .r({
                    page: page.currentPage,
                    pageSize: page.pageSize,
                    is_course: 1
                  })
                  .then((res) => {
                    // 渲染数据
                    callback({
                      total: 2,
                      data: [...res.data.list]
                    });
                  });
              },
              props: {
                label: 'name',
                value: 'id'
              }
            },
            {
              label: '活动属性',
              prop: 'activity_plan',
              span: 24,
              formslot: true,
              rules: [
                {
                  required: true,
                  message: '请选择活动属性',
                  trigger: 'blur'
                }
              ]
            },
            {
              label: '参与人数',
              prop: 'join',
              span: 8,
              controlsPosition: '',
              type: 'number',
              rules: [
                {
                  required: true,
                  message: '请填写参与人数',
                  trigger: 'blur'
                }
              ]
            },
            {
              label: '消耗能量值',
              span: 24,
              prop: 'subtotal',
              formslot: true
            },
            {
              label: '兑换会员',
              prop: 'exchange_member',
              span: 24,
              type: 'table',
              searchLabelWidth: 100,
              search: true,
              searchRules: [
                {
                  required: true,
                  message: '请输入活动名称',
                  trigger: 'blur'
                }
              ],
              rules: [
                {
                  required: true,
                  message: '请选择兑换的会员',
                  trigger: 'change'
                }
              ],
              children: {
                border: true,
                searchMenuSpan: 8,
                column: [
                  {
                    label: '姓名',
                    search: true,
                    prop: 'label'
                  },
                  {
                    label: '能量',
                    prop: 'energy'
                  }
                ]
              },
              formatter: (row) => {
                if (!row.label) return '';
                return `${row.label}`;
              },
              onLoad: ({ value }, callback) => {
                if (value) {
                  return;
                }
                dataApi.getMember.r().then((res) => {
                  callback({
                    total: 2,
                    data: [...res.data]
                  });
                });
              },
              props: {
                label: 'label',
                value: 'value'
              }
            },
            {
              label: '备注',
              span: 24,
              prop: 'remark',
              type: 'textarea'
            }
          ];
          this.depositTitle = '活动兑换';
          this.dialogVisible = true;
          break;
        case 'activitySign':
          this.option.column = [
            {
              label: '会员码',
              span: 21,
              prop: 'auth_code',
              rules: [
                {
                  required: true,
                  message: '请输入会员码',
                  trigger: 'blur'
                }
              ]
            },
            {
              label: '',
              span: 3,
              prop: 'activityInquire',
              formslot: true
            },
            {
              label: '签到活动',
              prop: 'memberSign',
              span: 24,
              formslot: true,
              rules: [
                {
                  required: true,
                  message: '请输入充值会员',
                  trigger: 'blur'
                }
              ]
            }
          ];
          this.depositTitle = '活动签到';
          this.dialogVisible = true;
          break;
        case 'addMember':
          this.option.column = [
            {
              label: '名称',
              span: 24,
              prop: 'name',
              type: 'input',
              search: true,
              rules: [
                {
                  required: true,
                  message: '请输入名称',
                  trigger: 'blur'
                }
              ]
            },
            {
              label: '手机号',
              prop: 'mobile',
              type: 'input',
              search: true,
              span: 24,
              editDisplay: true,
              required: true,
              rules: [
                {
                  validator: (rule, value, callback) => {
                    const myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                    if (!myreg.test(value)) {
                      callback(new Error('请输入正确的手机号'));
                    } else {
                      callback();
                    }
                  },
                  trigger: 'blur'
                }
              ]
            },
            {
              label: '性别',
              prop: 'sex',
              span: 24,
              value: 1,
              type: 'radio',
              dicData: [
                {
                  label: '男',
                  value: 1
                },
                {
                  label: '女',
                  value: 2
                }
              ],
              rules: [
                {
                  required: true,
                  message: '请选择性别',
                  trigger: 'blur'
                }
              ]
            },
            {
              type: 'select',
              label: '会员组',
              span: 24,
              display: true,
              prop: 'member_group_id',
              dicFlag: true,
              filterable: true,
              search: true,
              dicUrl: `${this.$baseUrl}general/get_member_group`,
              dicMethod: 'get',
              rules: [
                {
                  required: true,
                  message: '请选择会员组',
                  trigger: 'blur'
                }
              ]
            },
            {
              type: 'select',
              label: '会员级别',
              span: 24,
              display: true,
              prop: 'member_rank_id',
              dicFlag: true,
              filterable: true,
              search: 'true',
              dicUrl: `${this.$baseUrl}general/get_member_rank`,
              dicMethod: 'get',
              rules: [
                {
                  required: true,
                  message: '请选择会员级别',
                  trigger: 'blur'
                }
              ]
            }
          ];
          this.depositTitle = '新增会员';
          this.dialogVisible = true;
          break;
      }
    }
  },
  mounted() {
   
  }
};
