<!--能量值充值-->
<template>
  <div>
    <div
      class="dep"
      style="margin: 10px"
      @click="recharge()"
    >
      <h2>能量值充值</h2>
  </div>
  <!-- 弹窗 -->
  <el-dialog
    title="能量值充值"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="800px"
  >
    <!--  表单  -->
    <avue-form
      v-model="form"
      ref='form'
      :option="option"
      :defaults.sync="defaults"
      @submit="submit"
      @reset-change="emptytChange"
    >
      <!-- 能量值充值-选择查询会员-->
      <template slot="member">
        <div>
          <el-select
            placeholder="请选择充值会员"
            v-model="form.member"
          >
            <el-option
              v-for="item in dic"
              :key="item.value"
              :value="item"
              :label="item.label"
            ></el-option>
          </el-select>
        </div>
      </template>
      <!-- 能量值充值-充值金额 -->
      <template slot="money">
        <div>
          <el-input
            :disabled="moneyDisabled"
            v-model="form.money"
            placeholder="请输入充值金额"
            type="number"
          ></el-input>
        </div>
      </template>
      <!-- 能量值充值-获得 -->
      <template slot="energy">
        <div v-show="form.depositpay_type === 0">
          {{form.attribute === 1 ? energy:form.number }}
        </div>
        <div v-show="form.depositpay_type === 1">
          {{form.depositTemplate.length === 0 ? '请选择模板充值':form.depositTemplate.energy }}
        </div>
      </template>
      <!-- 能量值充值-剩余 -->
      <template slot="residue_energy">
        <div>
          {{form.member.length === 0 ? '请选择充值会员' : form.member.energy}}
        </div>
      </template>
      <!-- 能量值充值-能量值总和 -->
      <template slot="energy_sum">
        <div v-show="form.depositpay_type == 0">
          {{form.member.length === 0 ? '请选择充值会员' : totalEnergy(energy) }}
        </div>
        <div v-show="form.depositpay_type == 1">
          {{form.depositTemplate.length === 0 ? '请选择模板充值' : totalEnergy(form.depositTemplate.energy) }}
        </div>
      </template>
      <!-- 能量值充值-支付方式 -->
      <template slot="pay_type">
        <el-radio v-model="form.pay_type" label="cash">线下支付</el-radio>
        <el-radio v-model="form.pay_type" :disabled="!attributeType" label="wx_qrcode">微信支付</el-radio>
        <el-radio v-model="form.pay_type" :disabled="!attributeType" label="alipay_qrcode">支付宝支付</el-radio>
      </template>
      <!-- 能量值充值-查询按钮 -->
      <template slot="type">
        <div>
          <el-button
            style="margin-left: -105px"
            type="primary"
            @click="queryMember"
          >点击查询</el-button>
        </div>
      </template>
      <!-- 能量值充值-能量值属性 -->
      <template slot="attribute">
        <el-select
          placeholder="请选择充值类型"
          v-model="form.attribute"
        >
          <el-option :value="1" label="购买"></el-option>
          <el-option :value="2" label="赠送"></el-option>
        </el-select>
      </template>
      <!--  能量值充值-提示信息  -->
      <template slot="hint">
        <el-alert type="warning">
          {{ form.depositTemplate.length === 0 ? "选择模板充值显示比例": form.depositTemplate.remark}}
        </el-alert>
      </template>
      <!-- 能量值充值-充值模板 -->
      <template slot="depositpay_type">
        <el-radio
          v-model="form.depositpay_type"
          :disabled="customRadio"
          :label="0"
        >自定义充值</el-radio>
        <el-radio
          v-model="form.depositpay_type"
          :label="1"
        >模板充值</el-radio>
      </template>
      
      <!-- 能量值充值-模板充值 -->
      <template slot="depositTemplate">
        <div>
          <el-select
            placeholder="请选择充值模板"
            v-model="form.depositTemplate"
            @change="depositChange"
          >
            <el-option
              v-for="item in depositDic"
              :key="item.value"
              :value="item"
              :label="item.label"
            ></el-option>
          </el-select>
        </div>
      </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import '../list.scss';
import dataApi from '@/api/manage/workbench';

export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        member: '',
        select: '',
        pay_type: 'cash',
        money: '',
        remark: '',
        time: '',
        auth_code: '',
        coupon_id: '',
        depositpay_type: 1,
        depositTemplate: '',
        expires_time: '',
        attribute: 1,
        coupon_amount: 0
      },
      dic: [],
      moneyDisabled: true,
      customRadio: true,
      depositDic: [],
      defaults: {}
    };
  },
  computed: {
    energy() {
      // eslint-disable-next-line no-restricted-globals
      return isNaN(this.form.money * 1) ? 0 : this.form.money * 1;
    },
    attributeType() {
      // eslint-disable-next-line no-restricted-globals
      return this.form.attribute === 1;
    },
    // 表单配置项
    option() {
      return {
        disabled: false,
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        emptyText: '关闭',
        menuPosition: 'center',
        tabs: false,
        column: [
          {
            label: '姓名/手机号',
            span: 21,
            prop: 'select',
            rules: [
              {
                required: true,
                message: '请输入姓名/手机号',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '',
            span: 3,
            prop: 'type',
            formslot: true
          },
          {
            label: '充值会员',
            prop: 'member',
            type: 'select',
            span: 24,
            formslot: true,
            rules: [
              {
                required: true,
                message: '请输入充值会员',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '能量值属性',
            prop: 'attribute',
            span: 12,
            formslot: true,
            display: true,
            rules: [
              {
                required: true,
                message: '请输入选择支付方式',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '有效期',
            prop: 'expires_time',
            type: 'date',
            span: 12,
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() < Date.now();
              }
            }
          },
          {
            label: '充值方式',
            prop: 'depositpay_type',
            span: 24,
            formslot: true,
            rules: [
              {
                required: true,
                message: '请输入选择支付方式',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '充值模板',
            prop: 'depositTemplate',
            span: 24,
            formslot: true,
            display: true,
            rules: [
              {
                required: true,
                message: '请输入充值模板',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '充值金额',
            prop: 'money',
            span: 12,
            disabled: false,
            formslot: true,
            rules: [
              {
                required: true,
                message: '请输入充值金额',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '优惠金额',
            prop: 'coupon_amount',
            span: 12
          },
          {
            label: '充值数量',
            prop: 'number',
            span: 24,
            display: false,
            rules: [
              {
                required: true,
                message: '请输入充值数量',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '支付方式',
            prop: 'pay_type',
            span: 24,
            formslot: true,
            rules: [
              {
                required: true,
                message: '请输入选择支付方式',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '授权码',
            prop: 'code',
            span: 24,
            display: false,
            formslot: true,
            rules: [
              {
                required: true,
                message: '请输入授权码',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '剩余能量值',
            span: 8,
            prop: 'residue_energy',
            formslot: true
          },
          {
            label: '获得能量值',
            span: 8,
            prop: 'energy',
            formslot: true
          },
          {
            label: '能量值总数',
            span: 8,
            prop: 'energy_sum',
            formslot: true
          },
          {
            label: '说明',
            span: 24,
            type: 'textarea',
            prop: 'remark'
          },
          {
            label: '',
            prop: 'hint',
            span: 24,
            formslot: true
          }
        ]
      };
    }
  },
  methods: {
    clear() {
      this.form = {
        member: '',
        select: '',
        pay_type: 'cash',
        money: '',
        remark: '',
        time: '',
        auth_code: '',
        coupon_id: '',
        coupon_amount: 0,
        depositpay_type: 1,
        depositTemplate: '',
        attribute: 1
      };
    },
    // 卡片点击事件
    recharge() {
      this.dialogVisible = true;
    },
    // 点击按钮 查询会员列表
    queryMember() {
      if (this.form.select === '') {
        this.$refs.form.validateField('select');
      } else {
        dataApi.getMember.r({ keyword: this.form.select }).then(res => {
          this.dic = [];
          this.form.member = '';
          this.dic.push(...res.data);
        });
      }
    },
    depositChange() {
      this.form.money = this.form.depositTemplate.price;
    },
    emptytChange() {
      this.dialogVisible = false;
      this.clear();
    },
    submit(form, done) {
      form.member_id = form.member.value;
      form.auth_code = '';
      form.coupon_id = '';
      delete form.member;
      delete form.select;
      /*
        * depositTemplate 模板ID
        * */
      if (form.depositpay_type === 1) {
        form.energy = form.depositTemplate.energy;
        form.depositTemplate = form.depositTemplate.value;
      }

      dataApi.getDeposit.r(form).then(res => {
        this.$message.success(`充值成功,单号:${res.data.serialno}`);
        // 清空关闭表单;
        this.dic = [];
        this.clear();
        this.dialogVisible = false;
      });

      done();
    },
    totalEnergy(toupEnergy) {
      if (this.form.attribute === 1) {
        return Number(this.form.member.energy) + Number(toupEnergy);
      }
      return Number(this.form.member.energy) + Number(this.form.number);
    },
    getDepositDic() {
      dataApi.energyTpl.r().then(res => {
        this.depositDic = [...res.data.tpl];
      });
    }
  },
  // 监听单选按钮 显示/隐藏授权码
  watch: {
    'form.attribute'(val) {
      if (val === 2) {
        // 赠送时金额强制为0
        this.form.money = 0;
        // 开启自定义充值
        this.customRadio = false;
        this.form.depositpay_type = 0;
        this.defaults.number.display = true;
        this.defaults.money.display = false;
        this.defaults.coupon_amount.display = false;
      } else {
        this.customRadio = true;
        this.form.depositpay_type = 1;
        this.defaults.number.display = false;
        this.defaults.money.display = true;
        this.defaults.coupon_amount.display = true;
      }
    },
    'form.pay_type'(val) {
      if (val === 0) {
        if ('code' in this.defaults) {
          this.defaults.code.display = false;
        }
      } else if (val === 1 || val === 2) {
        this.defaults.code.display = true;
      }
    },
    'form.depositpay_type'(val) {
      if (val === 0) {
        if ('depositTemplate' in this.defaults) {
          this.form.depositTemplate = '';
          this.form.money = '';
          this.defaults.depositTemplate.display = false;
          this.defaults.attribute.display = true;
          this.moneyDisabled = false;
        }
      } else if (val === 1) {
        dataApi.energyTpl.r().then(res => {
          this.depositDic = [...res.data.tpl];
        });
        this.defaults.depositTemplate.display = true;
        // this.defaults.attribute.display = false;
        this.form.attribute = 1;
        this.moneyDisabled = true;
      }
    }
  },
  mounted() {
    this.getDepositDic();
    // this.defaults.depositTemplate.display = true;
    // this.defaults.attribute.display = false;
  }
};
</script>

<style scoped>
</style>
